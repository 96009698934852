import Navbar from "react-bootstrap/Navbar";
import { useIsAuthenticated } from "@azure/msal-react";
import { useStyles } from "./Header.styles";
import seequent from "../../assets/seequent.png";
import adminlogo from "../../assets/adminlogo.png";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useContext, useState } from "react";
import { SignOutMenuOption } from "../Login/SignOutMenuOption";
import { SignInMenuOption } from "../Login/SignInMenuOption";

import { ParkLordContext } from "../../App";
import { CharityModal } from "../GenericModal/GenericModal";
/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const Header = () => {
    const isAuthenticated = useIsAuthenticated();
    const { classes } = useStyles();
    const [expanded, setExpanded] = useState(false)
    const parkLord = useContext(ParkLordContext)
    const [showDonationModal, setShowDonationModal] = useState(false);

    const handleDonationCloseModal = async () => {
        setShowDonationModal(false)
    }

    return (
        <>
        <Navbar expanded={expanded} collapseOnSelect expand="lg" bg="black" variant="dark" className={classes.nav}>
            <Navbar.Brand href="/" className="my-1">
                <img className="d-inline-block align-top" height="30" src={seequent} alt="Seequent logo" />
            </Navbar.Brand>
            <Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav hidden={!isAuthenticated}>
                    <Nav.Link as={NavLink} to="#" href="#" style={{color:"orange"}} onClick={() => {setExpanded(false); setShowDonationModal(true) }}><i className="bi bi-cash-coin me-2"></i>Donate Here</Nav.Link>
                    <Nav.Link as={NavLink} to="/" href="#" onClick={() => {setExpanded(false)}}><i className="bi bi-calendar2-week me-2"></i>Book A Park</Nav.Link>
                    <Nav.Link as={NavLink} to="/myparks" href="#myparks" onClick={() => {setExpanded(false)}} ><i className="bi bi-car-front me-2"></i>Manage My Park</Nav.Link>
                    <Nav.Link as={NavLink} to="/parkmap" href="#parkmap" onClick={() => {setExpanded(false)}} ><i className="bi bi-map me-2"></i>Park Map</Nav.Link>
                    <Nav.Link as={NavLink} to="/about" href="#about" onClick={() => {setExpanded(false)}} ><i className="bi bi-info-circle me-2"></i>About Us</Nav.Link>
                    {parkLord?.adminBool && (<Nav.Link as={NavLink} to="/admin" href="#admin" onClick={() => { setExpanded(false) }} > <img height="20" src={adminlogo}/>Admin Dashboard</Nav.Link>)}
                </Nav>
                <Nav>
                    {isAuthenticated ? <SignOutMenuOption /> : <SignInMenuOption />}
                </Nav>
            </Navbar.Collapse>
        </Navbar>

        <CharityModal props={{ title: "ParkSeeq charities you can support", show: showDonationModal, handleClose: handleDonationCloseModal }}></CharityModal>
        </>
    );
};
