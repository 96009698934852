
// Function to check if a given date is a weekend (Saturday or Sunday)
import { daysShown } from "./constants";
function isWeekend(date: Date) {
    const day = date.getDay();
    return day === 0 || day === 6;
}

export function BusinessDays(numOfDays = daysShown) {
    const currentDate = new Date();
    const nextDays = [];
    for (let i = 0; i < numOfDays; i++) {
        const date = new Date(currentDate.getTime() + i * 24 * 60 * 60 * 1000);
        if (!isWeekend(date)) {
            nextDays.push(date);
        }
    }
    return nextDays
}

export function DayOfYear(today: Date) {
    const startOfYear: Date = new Date(today.getFullYear(), 0, 0);

    const diff: number = Number(today) - Number(startOfYear);

    const dayOfYear: number = Math.floor(diff / (1000 * 60 * 60 * 24));

    return dayOfYear
}

export function ToLocaleDateStringISO(date: Date, locales: Intl.LocalesArgument = 'en-GB'): string {
    const localDay = date.toLocaleDateString(locales, { day: "2-digit" })
    const localMonth = date.toLocaleDateString(locales, { month: "2-digit" })
    const localYear = date.toLocaleDateString(locales, { year: "numeric" })
    return `${localYear}-${localMonth}-${localDay}`
}

export async function requestNotificationPermission(): Promise<string> {
    console.log('Requesting permission...');
    const permission = await Notification.requestPermission();
    console.log(`Notification permission ${permission}.`);
    
    return permission;
}
