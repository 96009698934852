import { useContext, useState, useEffect } from "react";
import { UserContext } from "../Profile/ProfileContent";
import { ParkLordContext } from "../../App";
import { doc, setDoc, getDoc, deleteDoc, DocumentSnapshot, collection, collectionGroup, CollectionReference, DocumentReference, query, getDocs, } from 'firebase/firestore';
import { USER, WAITLIST, db } from '../../Firebase';
import { ToLocaleDateStringISO } from "../utils";
import { WaitList } from "../../types/WaitList.type";
import { User } from "../../types/User.type";
import { Booking } from "../../types/Booking.type";

export const Admin = () => {
    const userContext = useContext(UserContext);
    const parkLord = useContext(ParkLordContext)
    const [userDataMap, setUserDataMap] = useState<Map<string, User>>(new Map<string, User>())
    const [bookingDataMap, setBookingDataMap] = useState<Map<string, Booking>>(new Map<string, Booking>())
    const [waitListDataMap, setWaitListDataMap] = useState<Map<string, WaitList>>(new Map<string, WaitList>())
    
    //create a function that will get the documents from a collection in a firestore database

    useEffect(() => {
        async function loadingData() {
            const hashMap = new Map<string, User>()
            const filteredUserQuery = query(collection(db, "user"))
            const snappy = await getDocs(filteredUserQuery)
            snappy.forEach(doc => {
                const userData = doc.data() as User
                hashMap.set(doc.id, userData)
            });
            setUserDataMap(hashMap)
        }
        loadingData()
    }, [])
    useEffect(() => {
        async function loadingData() {
            const hashMap = new Map<string, Booking>()
            const filteredBookingsQuery = query(collection(db, "bookings"))
            const snappy = await getDocs(filteredBookingsQuery)
            snappy.forEach(doc => {
                const userData = doc.data() as Booking
                hashMap.set(doc.id, userData)
            });
            setBookingDataMap(hashMap)
        }
        loadingData()
    }, [])
    useEffect(() => {
        async function loadingData() {
            const hashMap = new Map<string, WaitList>()
            const filteredWaitListQuery = query(collection(db, "waitlist"))
            const snappy = await getDocs(filteredWaitListQuery)
            snappy.forEach(doc => {
                const userData = doc.data() as WaitList
                hashMap.set(doc.id, userData)
            });
            setWaitListDataMap(hashMap)
        }
        loadingData()
    }, []) 
    function searchies(tableName: string, inputName: string, searchedCol = 0 ) { //searchedCol is 0 by default, but can pass through a number optionally
        const input = document.getElementById(inputName) as HTMLInputElement;
        const filter = input.value.toUpperCase();
        const table = document.getElementById(tableName) as HTMLTableElement;
        const tr = table.getElementsByTagName("tr");
      
        // Loop through all table rows, and hide those who don't match the search query
        for (let i = 0; i < tr.length; i++) {
          const td = tr[i].getElementsByTagName("td")[searchedCol];
          if (td) {
            const txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              tr[i].style.display = "";
            } else {
              tr[i].style.display = "none";
            }
          }
        }
      }

    const UsersList = () => {
    
        return (
            
            <>
            <input type="text" id="usersInput" onKeyUp={() => searchies("usersTable", "usersInput")} placeholder="Search for Name of User" style={{width:"500px"}} />
            <br/>
                <table  id="usersTable" style={{overflowY:'scroll', height:'400px',display:'block',}}>
                    <tr>
                        <th>Name</th>
                        <th>Park Number</th>
                        <th>ConsentBool</th>
                        <th>AdminBool</th>
                        <th>Notification Token exists</th>
                    </tr>
                    {Array.from(userDataMap).map(([key, value]) => (
                        <tr key={key}>
                            <td>{key}</td>
                            <td>{value.parkNumber}</td>
                            <td>{value.consentBool.toString()}</td>
                            <td>{value.adminBool?.toString()?? false}</td>
                            <td>{(value.notificationTokens ? true : false).toString()}</td>
                        </tr>
                    ))}
                </table>
            </>
        );
    }    

    const BookingsList = () => {
        return (
            <>
            <input type="text" id="bookingsInput" onKeyUp={() => searchies("bookingsTable", "bookingsInput",3)} placeholder="Search for Booking by Park Number" style={{width:"500px"}} />
            <br/>
                <table id="bookingsTable" style={{overflowY:'scroll', height:'400px',display:'block',}}>
                    <tr>
                        <th>Booking Header</th>
                        <th>Date</th>
                        <th>Owner</th>
                        <th>Park Number</th>
                        <th>Reserved By</th>
                    </tr>
                    {Array.from(bookingDataMap).map(([key, value]) => (
                        <tr key={key}>
                            <td>{key}</td>
                            <td>{value.date}</td>
                            <td>{value.owner}</td>
                            <td>{value.parkNumber}</td>
                            <td>{value.reservedby ?? "Not Reserved"}</td>
                        </tr>
                    ))}
                </table>
            </>
        );
    }
    const WaitList = () => {
        return (
            <>
            <input type="text" id="waitListInput" onKeyUp={() => searchies("waitListTable", "waitListInput")} placeholder="Search for WaitList by Date (YYYY-MM-DD)" style={{width:"500px"}} />
            <br/>
                <table id="waitListTable" style={{overflowY:'scroll', height:'400px',display:'block',}}>
                    <tr>
                        <th>Date</th>
                        <th>Waiting Pool</th>
                    </tr>
                    {Array.from(waitListDataMap).map(([key, value]) => (
                        <tr key={key}>
                            <td>{key}</td>
                            <td>{value.waitingPool}</td>
                        </tr>
                    ))}
                </table>
            </>
        );
    }

    return (
        <div>
            {parkLord?.adminBool ? (
                <div>
                    <br />
                    <UsersList/> {/* Await the UsersList function */}
                    <br />
                    <BookingsList />
                    <br />
                    <WaitList />
                    <br />
                </div>
            ) : (
                <div>not admin</div>
            )}
        </div>
    )
}
