import { useContext, useEffect, useState } from "react";
import { UserContext } from "../Profile/ProfileContent";
import { doc, setDoc, getDoc, deleteDoc } from "firebase/firestore";
import { BOOKINGS, db, fetchBookingsFiltered } from "../../Firebase";
import { Booking } from "../../types/Booking.type";
import { Alert, Container, Row } from "react-bootstrap";
import { BusinessDays, ToLocaleDateStringISO } from "../utils";
import { Bookable } from "../MyParkComponents/Bookable";
import { Booked } from "../MyParkComponents/Booked";
import { OfferableDays } from "../MyParkComponents/OfferableDays";
import { GenericModal } from "../GenericModal/GenericModal";
import { ParkLordContext } from "../../App";

// Polling interval must be balanced against the Firebase free tier request limit
// See https://firebase.google.com/pricing/ for more information
const pollingInterval = 1000 * 60 * 15  // 15 minutes in milliseconds

export const MyPark = () => {

    const userContext = useContext(UserContext)
    const parkLord = useContext(ParkLordContext)
    const [bookings, setBookings] = useState<Booking[] | null>(null)
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState<string | null>(null)
    const [modalText, setModalText] = useState<string | null>(null)
    
    const handleCloseModal = () => setShowModal(false);

    const ALREADY_BOOKED_TITLE = 'Park Already Booked'

    useEffect(() => {
        const fetchData = async () => {
            setBookings(await fetchBookingsFiltered())
        }
        fetchData()
        const interval = setInterval(async () => {
            fetchData()
        }, pollingInterval)
        return () => clearInterval(interval)
    }, [])

    const getParkId = (parkNumber: number, date: string) => {
        return `park${parkNumber}-${date}`
    }

    const cancelBooking = async (props: any) => {
        const dbBookings = await fetchBookingsFiltered()
        const currentDbBooking = dbBookings.find((dbBooking: Booking) => dbBooking.date == props.booking.date && dbBooking.owner == props.booking.owner && dbBooking.parkNumber == props.booking.parkNumber)
        if (currentDbBooking && currentDbBooking.reservedby != props.booking.reservedby) {
            setModalTitle(ALREADY_BOOKED_TITLE)
            setModalText(`Sorry, your park for ${props.date.toLocaleDateString(undefined, { weekday: "short", day: "numeric", month: "short"})} has already been booked by ${currentDbBooking.reservedby}`)
            setShowModal(true)
            setBookings(dbBookings)
            return
        }
        const parkId = getParkId(props.booking.parkNumber, props.booking.date)
        console.log(`cancel rental ${parkId}`)
        await deleteDoc(doc(db, BOOKINGS, parkId)).then(async () => {
            setBookings(await fetchBookingsFiltered())
        })
    }

    const createRentalEntry = async (props: any) => {
        if (props.bookings == null) {
            const dateStringIso = ToLocaleDateStringISO(props.date)
            const parkId = getParkId(parkLord?.parkNumber as number, dateStringIso)
            const collectionRef = doc(db, BOOKINGS, parkId);
            const booking: Booking = {
                parkNumber: parkLord?.parkNumber as number,
                owner: userContext?.userPrincipalName.toLowerCase() as string,
                date: dateStringIso,
                reservedby: ""
            }
            console.log(`Create entry: ${parkId}`)
            await setDoc(collectionRef, booking, { merge: true })
                .then(async () => {
                    console.log("Successful");
                    const bookingsFiltered = await fetchBookingsFiltered();
                    setBookings(bookingsFiltered);
                })
                .catch((error) => {
                    console.log(`Unsuccessful returned error ${error}`)
                });
        }
    }

    const renderRow = (day: Date, index: number) => {
        const props = { date: day, owner: userContext?.userPrincipalName.toLowerCase(), parkNumber: parkLord?.parkNumber as number, }
        let row = <OfferableDays props={{ ...props, createRentalEntry }} key={index}></OfferableDays>;
        if (bookings != null && parkLord != null) {
            bookings.map((booking: Booking) => {
                const dateStringIso = ToLocaleDateStringISO(day)
                if (booking.date == dateStringIso && booking.parkNumber == parkLord.parkNumber) {
                    const props = { booking: booking, date: day, owner: userContext?.userPrincipalName.toLowerCase(), parkNumber: parkLord.parkNumber }
                    row = booking.reservedby == ""
                        ? <Bookable props={{ ...props, cancelBooking }} key={index}></Bookable>
                        : <Booked props={props} key={index}></Booked>;
                }
            });
        }
        return row
    }

    return (
        <>
            {parkLord ?
            <Container>
                <h3 className="fw-light text-center mb-3">My Park <code className="fs-3 ms-2">#{parkLord.parkNumber}</code></h3>
                <Row xs={2} md={3} lg={6} className="g-3 justify-content-evenly align-items-center mb-3">
                    {BusinessDays().map((day: Date, index: number) => {
                        return (renderRow(day, index))
                    })}
                </Row>
                <GenericModal props={{ title: modalTitle, text: modalText, show: showModal, handleClose: handleCloseModal}}></GenericModal>
            </Container>
            : <Container>
                <Alert variant={"danger"} className="mt-3">
                    <h3 className="display-6 fs-3 text-center">Contact Seequent office Admin about registering as a Park Lord</h3>
                </Alert>
            </Container>}
        </>
    );
}
