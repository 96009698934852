import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Bookings } from './components/BookingsComponents/Bookings';
import { MyPark } from './components/MyParkComponents/MyPark';
import { About } from './components/MyParkComponents/About';
import { Admin } from './components/Admin/Admin';
import { ParkMap } from './components/MyParkComponents/ParkMap';


const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                index: true,
                element: <Bookings />
            },
            {
                path: 'myparks',
                element: <MyPark />
            },
            {
                path: 'parkmap',
                element: <ParkMap/>
            },
            {
                path: 'about',
                element: <About />
            },
            {
                path: 'admin',
                element: <Admin />
            },
        ],
    },
]);

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <RouterProvider router={router} />
        </MsalProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
