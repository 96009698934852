import { Button} from "react-bootstrap";
import { CarparkCard } from "../CarparkCard/CarparkCard";
import { useCallback, useContext, useEffect, useState } from "react";
import { requestNotificationPermission } from "../utils";

// TODO : remove feature flag

import config from "../../config.json";
import { saveFirebaseToken } from "../../Firebase";
import { UserContext } from "../Profile/ProfileContent";

export const NotifyButton = ({ notify, setNotify }: any) => {
    const userContext = useContext(UserContext);

    const changeNotify = async (notify: boolean) => {
        if (notify && Notification.permission === "default") {
            // Notification requests can only be called in handlers triggered by user action.
            // If we trigger the request without any user interaction, it will always return denied.
            // In iOS, if the web app hasn't been added to the Home Screen, it will always get denied.
            const permission = await requestNotificationPermission();
            if (permission === "granted") {
                await saveFirebaseToken(userContext?.userPrincipalName.toLowerCase())
            }
        }
        setNotify(notify);
    }
    if(config.REACT_APP_FEATURE_FLAG_NOTIFY) {
        return ( notify ?
            <Button variant="outline-warning w-100" onClick={async () => await changeNotify(false)}>Don't Notify</Button>
            : <Button variant="outline-success w-100" onClick={async () => await changeNotify(true)}>Notify Me</Button> )
    } else {
        return <Button variant="secondary w-100" disabled>Book</Button>
    }
};
export const FreeSpace = ({ props }: { props: any }) => {
    const [notify, setNotify] = useState(false);
    const [poolCount, setPoolCount] = useState(0);
    const sharedButton = useCallback(async () => {
        if (notify) {
            await props.removeFromWaitList(props.date)
        } else {
            await props.addToWaitList(props.date)
        }
        setNotify(!notify)
    }, [notify]);

    useEffect(() => {
        const checkInWaitlist = async () => {
            setNotify(await props.inWaitList(props.date))
        }
        checkInWaitlist()
    }, [])

    useEffect(() => {
        const updatePoolCount = async () => {
            setPoolCount(await props.waitListPoolCount(props.date))
        }
        updatePoolCount()
    }, [notify])

    const content = () => {
        if(config.REACT_APP_FEATURE_FLAG_NOTIFY){
            return props.available > 0 ? `${props.available} available `: `${poolCount} waiting`
        } else {
            return `${props.available} available `
        }
    }
    return (
        <CarparkCard
            header={props.date.toLocaleDateString(undefined, { weekday: "short", day: "numeric", month: "short"})}
            content={content()}
            button={props.available > 0 ?
                <Button variant="success w-100" onClick={() => props.book(props.date)}>Book</Button> :
                <NotifyButton setNotify={sharedButton} notify={notify} />
            }
        />
    );
}
