import { Button } from "react-bootstrap";
import { CarparkCard } from "../CarparkCard/CarparkCard";

export const BookedSpace = ({ props }: { props: any }) => {

    return (
        <CarparkCard 
        header={props.date.toLocaleDateString(undefined, { weekday: "short", day: "numeric", month: "short"})} 
        content={<span><code className="fs-6 lh-sm me-1">#{props.parkNumber}</code> booked</span>} 
        button={<Button variant="warning w-100" onClick={() => props.cancelBooking(props.date)} >Cancel</Button>} />
    );
}