import { Col, Card } from "react-bootstrap";

export const CarparkCard = ({ header, content, button }: { header: string, content: any, button: any }) => {

    return (
        <Col>
            <Card className="text-center d-flex justify-content-around">
                <Card.Body>
                    <Card.Title className="fw-normal text-nowrap">{header}</Card.Title>
                    <p className="my-3">
                        {content}
                    </p>
                    {button}
                </Card.Body>
            </Card>
        </Col>
    );
}