import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    mapImage: {
        maxWidth:"100%", // for auto scaling
        maxHeight:"100%",
        display: "block", // centering
        marginLeft: "auto",
        marginRight: "auto",
    },
}));
