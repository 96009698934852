import { useContext, useEffect, useState } from "react";
import { UserContext } from "../Profile/ProfileContent";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { BOOKINGS, WAITLIST, db, fetchBookingsFiltered } from "../../Firebase";
import { Booking } from "../../types/Booking.type";
import { Button, Container, Row } from "react-bootstrap";
import { BusinessDays, DayOfYear, ToLocaleDateStringISO } from "../utils";
import { FreeSpace } from "../BookingsComponents/FreeSpace"
import { BookedSpace } from "../BookingsComponents/BookedSpace"
import { CharityModal, GenericModal } from "../GenericModal/GenericModal";
import { WaitList } from "../../types/WaitList.type";

// Polling interval must be balanced against the Firebase free tier request limit
// See https://firebase.google.com/pricing/ for more information
const pollingInterval = 1000 * 60 * 15  // 15 minutes in milliseconds

export const Bookings = () => {
    const userContext = useContext(UserContext);
    const [bookings, setBookings] = useState<Booking[] | null>(null)
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState<string | null>(null)
    const [modalText, setModalText] = useState<string | null>(null)

    const handleCloseModal = () => setShowModal(false);

    const PARK_OFFER_CANCELLED_TITLE = 'Park Offer Cancelled'
    const [showDonationModal, setShowDonationModal] = useState(false);

    const handleDonationCloseModal = async () => {
        setShowDonationModal(false)
    }

    useEffect(() => {
        const fetchData = async () => {
            setBookings(await fetchBookingsFiltered())
        }
        fetchData()
        const interval = setInterval(async () => {
            fetchData()
        }, pollingInterval)
        return () => clearInterval(interval)
    }, [])

    const getParkId = (parkNumber: number, date: string) => {
        return `park${parkNumber}-${date}`
    }

    const availability = (bizDay: Date) => {
        const availableBookings = bookings?.filter(booking => {
            if (booking.date != undefined) {
                const bizday = DayOfYear(bizDay)
                const bookday = DayOfYear(new Date(booking.date))
                const available = (bizday == bookday && (booking.reservedby == "" || booking.reservedby == userContext?.userPrincipalName.toLowerCase()))
                return available
            } else {
                return []
            }

        })
        return availableBookings
    }

    const isCorrectDate = (date: Date): boolean => {
        return date instanceof Date && isFinite(+date);
    };
    
    const availabilityNum = (bizDay: Date) => {
        if (isCorrectDate(bizDay)) {
            const availableBookings = availability(bizDay)
            return availableBookings?.length ? availableBookings?.length : 0
        }
    }

    const removeUserFromDbWaitlist = async (poolDate: Date, username: string) => {
        const docRef = doc(db, WAITLIST, ToLocaleDateStringISO(poolDate))
        const waitingPool = (await getDoc(docRef)).data() as WaitList
        if (waitingPool) {
            const parkeesWaitList = new Set(waitingPool.waitingPool) 
            parkeesWaitList.delete(username);
            const updateObject = { waitingPool: [...parkeesWaitList] }
            await setDoc(docRef, updateObject, {merge: true})
        }
    }

    const book = async (bizDay: Date) => {
        const dbBookings = await fetchBookingsFiltered()
        const availableDbBooking = dbBookings.find((dbBooking: Booking) => dbBooking.date == ToLocaleDateStringISO(bizDay) && dbBooking.reservedby == "")
        if (!availableDbBooking) {
            setModalTitle(PARK_OFFER_CANCELLED_TITLE)
            setModalText(`Sorry, the park offer for ${bizDay.toLocaleDateString(undefined, { weekday: "short", day: "numeric", month: "short"})} has already been cancelled`)
            setShowModal(true)
            setBookings(dbBookings)
            return
        }
        const targetId = getParkId(availableDbBooking.parkNumber, availableDbBooking.date)
        const collectionRef = doc(db, BOOKINGS, targetId);
        // if you are removing your own lock
        const updateObject = { reservedby: userContext?.userPrincipalName.toLowerCase() }

        await setDoc(collectionRef, updateObject, { merge: true }).then(async () => {
            // refresh the bookings list once the booking has been made
            setBookings(await fetchBookingsFiltered())
            // remove the user from the waitlist
            const username = userContext?.userPrincipalName.toLowerCase();
            if (username)
                await removeUserFromDbWaitlist(bizDay, username);
        });
    }
    
    const addToWaitList = async (poolDate: Date) => {
        const username = userContext?.userPrincipalName.toLowerCase();
        if(username) {
            const docRef = doc(db, WAITLIST, ToLocaleDateStringISO(poolDate))
            const docSnap = await getDoc(docRef);
            let parkeesWaitList;
            let waitingPool;
            if (!docSnap.data()) {
                parkeesWaitList = [username]
            } else {
                waitingPool = docSnap.data() as WaitList
                parkeesWaitList = waitingPool.waitingPool
                parkeesWaitList.push(username);
            }

            const waitListNoDuplicates = [...new Set(parkeesWaitList)];
            const updateObject = { waitingPool: waitListNoDuplicates }
            await setDoc(docRef, updateObject, {merge: true})
            
        }

    }
    const removeFromWaitList = async(poolDate: Date) => {
        const username = userContext?.userPrincipalName.toLowerCase()
        if(username){
            await removeUserFromDbWaitlist(poolDate, username)
        }

    }

    const cancelBooking = async (bizDay: Date) => {
        const bookings = availability(bizDay)
        if (!bookings) {
            return
        }
        const booking = bookings[0]
        const collectionRef = doc(db, BOOKINGS, `park${booking.parkNumber}-${booking.date}`);
        const updateObject = { reservedby: "" }
        await setDoc(collectionRef, updateObject, { merge: true }).then(async () => {
            // refresh the bookings list once the booking has been made
            setBookings(await fetchBookingsFiltered())    
    });
}
    
    const containsOwnBooking = (day: Date) => {
        // Used to show which bookings YOU have booked
        const availableBookings = bookings?.filter(booking => {
            if (booking.reservedby != undefined) {
                return (booking.reservedby == userContext?.userPrincipalName.toLowerCase() && ToLocaleDateStringISO(day) == booking.date)
            } 
        })
        return availableBookings
    }
    const inWaitList = async (day: Date):Promise<boolean> => {
        const username = userContext?.userPrincipalName.toLowerCase();
        if(username) {
            const docRef = doc(db, WAITLIST, ToLocaleDateStringISO(day))
            const docSnap = await getDoc(docRef);
            const waitingPool = docSnap.data() as WaitList
            if (!waitingPool)
                return false;
            const parkeesWaitList = waitingPool.waitingPool
            const waitListNoDuplicates = new Set(parkeesWaitList)
            return waitListNoDuplicates.has(username)
        }
        return false
    }
    const waitListPoolCount = async (day: Date):Promise<number> => {
        const username = userContext?.userPrincipalName.toLowerCase();
        if(username) {
            const docRef = doc(db, WAITLIST, ToLocaleDateStringISO(day))
            const docSnap = await getDoc(docRef);
            let dayCount;
            let waitingPool;
            if (!docSnap.data()) {
                return 0
            } else {
                waitingPool = docSnap.data() as WaitList
                const parkeesWaitList = waitingPool.waitingPool
                dayCount = new Set(parkeesWaitList) 
                return dayCount.size
            }
        }
        return 0
    }


    const renderRow = (day: Date, index: number) => {
        
        const check = containsOwnBooking(day)
        const parkNumber = check != undefined ? check.length > 0 ? check[0].parkNumber : 0 : 0
        const available = parkNumber == 0 ? availabilityNum(day) : 0
        const isBooked = parkNumber > 0 
        const props = {waitListPoolCount, inWaitList, date: day, parkNumber: parkNumber, available: available, book: book, cancelBooking: cancelBooking, addToWaitList: addToWaitList, removeFromWaitList: removeFromWaitList}   
        return (
            // have i booked that day
            isBooked ? <BookedSpace props={props} key={index}></BookedSpace>
                // if not how many available spaces are there
                : <FreeSpace props={props} key={index}></FreeSpace>
        )
    }

    return (
        
        <Container>
            <h3 className="fw-light text-center mb-3">Book a Park and <Button variant="success" size="lg" className="align-baseline" onClick={() => setShowDonationModal(true)}>donate $5 per booking here</Button></h3>
            <Row xs={2} md={3} lg={6} className="g-3 justify-content-evenly align-items-center mb-3">
                {BusinessDays().map((day: Date, index: number) => {
                    return (renderRow(day, index))
                })}    
            </Row>
            <GenericModal props={{ title: modalTitle, text: modalText, show: showModal, handleClose: handleCloseModal}}></GenericModal>
            <CharityModal props={{ title: "ParkSeeq charities you can support", show: showDonationModal, handleClose: handleDonationCloseModal }}></CharityModal>
        </Container>
        
    );
}
