import { Button } from "react-bootstrap";
import { CarparkCard } from "../CarparkCard/CarparkCard";

export const Bookable = ({props}: {props: any}) => {
    return(
        <CarparkCard 
        header={props.date.toLocaleDateString(undefined, { weekday: "short", day: "numeric", month: "short"})} 
        content={`Open to bookings`} 
        button={<Button variant="warning w-100" onClick={() => props.cancelBooking(props)}>Cancel</Button>}/>
    );
}
