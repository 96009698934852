import { Button, Container } from "react-bootstrap";
import { CarparkCard } from "../CarparkCard/CarparkCard";
import { GenericModal } from "../GenericModal/GenericModal";
import { useState } from "react";

export const Booked = ({props}: {props: any}) => {

    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState<string | null>(null)
    
    const handleCloseModal = () => setShowModal(false);
    const handleOpenModal = () => {
        setModalText(`Booked by ${getName(props.booking.reservedby)} (${props.booking.reservedby})`)
        setShowModal(true)
    }
    const BOOKING_INFO_TITLE = "Booking Info"
    
    const getName = (email: string): string => {
        const nameComponents = email.split("@")[0].split(".")
        const name = nameComponents.map((nameComponent) => capitalise(nameComponent)).join(" ")
        return name
    }

    const capitalise = (word: string): string => {
        return word.charAt(0).toUpperCase() + word.slice(1)
    }

        return(
        <div>
            <CarparkCard 
                header={props.date.toLocaleDateString(undefined, { weekday: "short", day: "numeric", month: "short"})} 
                content={<a href="#" className="link-offset-2" onClick={() => handleOpenModal()}><i className="bi bi-info-circle me-2"></i>Booked</a>} 
                button={<Button variant="secondary w-100" disabled><s>&nbsp;Cancel&nbsp;</s></Button>} />
            <GenericModal props={{ title: BOOKING_INFO_TITLE, text: modalText, show: showModal, handleClose: handleCloseModal}} />
        </div>
    );
}
