import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    nav: {
        padding: "8px 16px"
    },
    spacer: {
        flexGrow: 1
    }
}));
