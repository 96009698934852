
import { Link, Typography } from "@mui/material";
import { Container } from "react-bootstrap";
import { parkSeeqDocUrl } from "../constants";

export const About = () => {
    const header = () => {
        return (
            <>
        <Container> 
            <Typography variant="h3" >
                About us
            </Typography>
        </Container>  
            </>)
    }
    const aboutUsBlob = () => {
        return (
            <>
        <Container> 
            <Typography variant="body1" >
            <h3>The People's Front For The Liberation Of Carparks</h3>
            <br/>
            We are a HackYeah team - keen to help more of our staff who need to drive to work, to park at work as cheap as possible.
            Our solution is to incentivise staff (who rent a park from Seequent) to share their carparks when not in use. It's a passion project, with interesting technology, and we collectively decide it's future.
            <br/>
            <br/>
            Building it:
            <br/>
            Julian Cone, Rachel Carpenter, Amy O'Hare, Melissa Vachod, Julien Pringot, Johan Vorster, Hina Hamad, Nico Valarino, Vince Milne, Cameron Scott, Renier Coetzer, Brendan McDermott, Liang Yu, Kevin Alcock, Josh Yee, Lisa Towey, Damon Ross.
            <br/>
            Testing it:
            <br/>
            Kelsey Vavasour, Eli Poizat, Anja Wood, Nur Muhammad Sirat, Jaco Pieterse, Ryan Miller, Rachel Rowlands-Clarke, Ding-Ding Fan, Tim Schur, Bryan Morgan, Sarah Daly
            <br/>
            <br/>
            Wanna help?  Got a Question?  Want to suggest a change/improvement?
            <br/>
            Join: #peoples_front_for_the_liberation_of_carparks
            <br/>
            <Link href={parkSeeqDocUrl}>Visit here for more resources online</Link>
            
            </Typography>
        </Container>  
            </>)
    }
    const faqBlob = () => {
        const qaTupleList = []
        qaTupleList.push(["What is a ParkLord?", "A ParkLord is someone who currently rents a carpark from Seequent"])
        qaTupleList.push(["What is a Parkee?", "A Parkee is someone who is looking to park their car in a carpark that is currently not being used by the ParkLord."])
        qaTupleList.push(["What is ParkSeeq?", "ParkSeeq is the new way for ParkLords to offer and schedule out their park space, to Parkees."])
        qaTupleList.push(["What's in it for me?", "ParkLords get to help out their workmates by offering up their unused carpark, as well as fundraise for a good cause. Parkees get a premium carpark at work for a donation that goes straight to charity"])
        qaTupleList.push(["Why use this over the carpark slack channel?", "ParkSeeq allows simpler scheduling and planning, with the added bonus of fundraising for charity. Introducing a charity donation incentivises the ParkLords to offer up their park. Although free, the current slack channel does not incentivise enough ParkLords."])
        qaTupleList.push(["Fundraising for Charity?", "Ultimately Parkees will have to pay for the usage of a premium (close to work) carpark. Payment is to be sent directly to a charity (and may be tax deductible) fundraiser."])
        qaTupleList.push(["How do I Pay/Donate?", "Common funds sources such as gofundme's will be used periodically, with the lump sum being donated to the charity chosen at the end of the period."])
        qaTupleList.push(["Who's keeping track?", "Ideally this is a trust based system, but we can do reminders based on recorded bookings and donations."])
        qaTupleList.push(["What happens if someone has taken my assigned carpark?", "Unfortunately  mistakes do happen, but we cannot police this. We ask that everyone uses and follows ParkSeeq rules."])
        qaTupleList.push(["More Questions?", "Jump into the #peoples_front_for_the_liberation_of_carparks slack channel!"])
        qaTupleList.push(["What if I booked a park and later discover I no longer need it?", "Cancel your booking ASAP to allow someone else to use it.  If you cancel too late - we do ask you to pay for that day - but will leave that to you:  if you prevented someone else from using the park you effectively caused them to pay for a park elsewhere AND a charity misses out!"])
        qaTupleList.push(["What if I am a parklord and need my park back but someone booked it that day?", "Sadly someone else made plans and taking it back off them is extremely disruptive to them, so you will need to negotiate with them.  If they decline, you are now in the position of a parkee - try booking a park.  Learn from this experience so that you can try to avoid in future."])
        qaTupleList.push(["Why can't Seequent provide enough free parks for everyone?", "Because there are just not enough.  Most companies around NZ and the world do not have enough spaces for their staff.  "])
        qaTupleList.push(["Why can't we make all parks available all of the time and noone rents them like parklords do currently?", "We could.  But it means significantly more expensive parking for all - with no guarantees of getting a park for those staff who need it most."])
        qaTupleList.push(["I want to change/improve the app.", "Cool, join the #peoples_front_for_the_liberation_of_carparks slack channel and tell us."])
        qaTupleList.push(["As a parklord can I get some money when people book my space?", "Our long-term aim is to offer some payment split between charity and parklord to incentivise more parklords, but it is much effort to get to that point."])
        qaTupleList.push(["Do I have to use this app?", "We hope everyone will want to support their colleagues.  Even for a parklord who wants to give his park just 1 day in a year.  But, it is entirely optional - and so darn simple to use - we'd be sad if you did not."])
        return (
            <>
        <Container> 
            <Typography variant="h3" className="mb-4">
                Frequently Asked Questions
            </Typography>
            <Typography variant="body1" >
                <ul>
                    {
                        qaTupleList.map((singleQa) => (
                            <>
                            <li><b>{singleQa[0]}</b></li>
                            <ul>
                                <li>{singleQa[1]}</li>
                            </ul>
                            </>
                        ))
                    }
                </ul>
            </Typography>
        </Container>  
            </>)
    }


    return (
        <>
        <br/>
        {header()}
        <br/>
        {aboutUsBlob()}
        <br/>
        {faqBlob()}
        </>
    );
}
