import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { IPublicClientApplication } from "@azure/msal-browser";
import { Nav } from "react-bootstrap";

export function handleLogin(instance: IPublicClientApplication) {
    instance.loginRedirect(loginRequest).catch(error => {
        console.error(error);
    });
}

/**
 * Renders a Nav.Link which, when selected, will open a popup for login
 */
export const SignInMenuOption = () => {
    const { instance } = useMsal();

    return (
        <Nav.Link onClick={() => handleLogin(instance)}><i className="bi bi-box-arrow-right me-2"></i>Sign In</Nav.Link>
    );
}
