import { useMsal } from "@azure/msal-react";
import { createContext, useEffect, useState } from "react";
import { loginRequest } from "../../authConfig";
import { callMsGraph } from "../../graph";
import { Spinner } from "react-bootstrap";
import { UserMS } from "../../types/UserMS.type";
import { saveFirebaseToken } from '../../Firebase';

export const UserContext = createContext<UserMS | null>(null);

export const ProfileContent = ({ children }: { children: JSX.Element }) => {
    const { instance, accounts } = useMsal();
    const [userMS, setUserMS] = useState<UserMS | null>(null);

    useEffect(() => {
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            callMsGraph(response.accessToken).then(response => setUserMS(response));
            // console.log(`graphData: ${JSON.stringify(userMS)}`)
        });
    }, [])

    const AppReady = () => {
        if (userMS) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        const saveToken = async (userPrincipalName: string) => {
            await saveFirebaseToken(userPrincipalName)
        }
        if (userMS) {
            saveToken(userMS?.userPrincipalName.toLowerCase())
        }
    }, [userMS])

    return (
        <>
            <h4 className="display-6 my-2 text-center">Welcome {AppReady() ? userMS?.givenName : ""}&#128406;</h4>
            {AppReady() ? (
                <UserContext.Provider value={userMS}>
                    {children}
                </UserContext.Provider>
            ) : (
                <div className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}
        </>
    );
};
