
import { Typography } from "@mui/material";
import { Container } from "react-bootstrap";
import carparkMap from "../../assets/carparkMap.png";
import { useStyles } from "./ParkMap.styles";


export const ParkMap = () => {
    const { classes } = useStyles();

    const header = () => {
        return (
            <>
        <Container> 
            <Typography variant="h3" align="center" >
                Find your Park
            </Typography>
        </Container>  
            </>)
    }
    const parkMapImage = () => {
        return (
            <>
        <img src={carparkMap} alt="logo" className={classes.mapImage}/>
            </>)
    }


    return (
        <>
        <br/>
        {header()}
        <br/>
        {parkMapImage()}
        </>
    );
}
