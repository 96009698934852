import { Checkbox, FormControlLabel, Link } from "@mui/material";
import React from "react";
import { Modal, Button, Stack} from "react-bootstrap";
import { useStyles } from "./GenericModal.styles";
import { charity0, charity1, charity2, seequentHomeUrl, tosUrl } from "../constants";

export const GenericModal = ({props}: {props: any}) => {
    return (
        <Modal show={props.show} onHide={props.handleClose} backdrop="static" keyboard={false}>
            <Modal.Header className="border border-bottom-0">
                <Modal.Title className="fs-4" style={{color: "#d63384"}}>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0">
                {props.text}
            </Modal.Body>
            <Modal.Footer className="border border-top-0">
                <Button variant="primary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export const TosModal = ({props}: {props: any}) => {
    const { classes } = useStyles();
    const [checked, setChecked] = React.useState(false);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
      };

    return (
        <Modal show={props.show} onHide={props.handleClose} backdrop="static" keyboard={false}>
            <Modal.Header className="border border-bottom-0">
                <Modal.Title className="fs-4" style={{color: "#d63384"}}>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0">
                <Link href={tosUrl} target="_blank">Terms of Service</Link>
            </Modal.Body>
            <br/>
            <div className={classes.div}>
                <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} />} label="I have read and agree to the terms of service" />
            </div>
            { checked && (
            <Modal.Footer className="border border-top-0">
                <Button variant="primary" onClick={props.handleClose}>
                    Accept
                </Button>
                <Button variant="primary" href={seequentHomeUrl}>
                    Decline
                </Button>
            </Modal.Footer>
            )
            }
            { !checked && (
            <Modal.Footer className="border border-top-0">
                <Button variant="primary" onClick={props.handleClose} disabled>
                    Accept
                </Button>
                <Button variant="primary" href={seequentHomeUrl}>
                    Decline
                </Button>
            </Modal.Footer>
            )
            }

        </Modal>
    );
}
export const CharityModal = ({props}: {props: any}) => {
    return (
        <Modal style={{marginTop:"20vh"}} show={props.show} onHide={props.handleClose} >
            <Modal.Header className="border border-bottom-0">
                <Modal.Title className="fs-4" style={{color: "#d63384"}}>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0">
                <Stack direction="horizontal" gap={3}>
                    <div className="p-2">{charity0.title}</div>
                    <div className="p-2 ms-auto"> <Button variant="success w-100" href={charity0.link} >Donate $5</Button></div>
                </Stack>
                <Stack direction="horizontal" gap={3}>
                    <div className="p-2">{charity1.title}</div>
                    <div className="p-2 ms-auto"> <Button variant="success w-100" href={charity1.link} >Donate $5 </Button></div>
                </Stack>
                <Stack direction="horizontal" gap={3}>
                    <div className="p-2">{charity2.title}</div>
                    <div className="p-2 ms-auto"> <Button variant="success w-100" href={charity2.link} >Donate $5</Button></div>
                </Stack>
            </Modal.Body>
            <br/>
            <Modal.Footer className="border border-top-0">
                <Button variant="primary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
            

        </Modal>
    );
}
