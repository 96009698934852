// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { collection, doc, setDoc, getDocs, getFirestore, query, where, getDoc } from 'firebase/firestore';
import { getAuth, signInAnonymously } from 'firebase/auth';
import config from "./config.json";
import { Booking } from "./types/Booking.type";
import { ToLocaleDateStringISO } from "./components/utils";
import { getMessaging, getToken } from "firebase/messaging";

    // Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker
//   `messaging.onBackgroundMessage` handler.
import { onMessage } from "firebase/messaging";
import { User } from "./types/User.type";

console.log(`config environment: ${config.REACT_APP_ENVIRONMENT}`)

// config.release.json overrides the config file for the "production" environment
// config.dev.json overrides the config file for "development" environment
// See the deployment pipelines for this transformation
const firebaseConfig = {
  apiKey: config.REACT_APP_apiKey,
  authDomain: config.REACT_APP_authDomain,
  projectId: config.REACT_APP_projectId,
  storageBucket: config.REACT_APP_storageBucket,
  messagingSenderId: config.REACT_APP_messagingSenderId,
  appId: config.REACT_APP_appId,
  measurementId: config.REACT_APP_measurementId
};

const UrlFirebaseConfig = new URLSearchParams(
  firebaseConfig.toString()
);

const swUrl = `http://localhost:3000/firebase-messaging-sw.js?${UrlFirebaseConfig}`;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
signInAnonymously(auth)
  // .then((e: any) => {
  //   console.log(`${e}`)
  // })
  .catch((error: any) => {
    console.log(`${error.code}: ${error.message}`)
  });
getAnalytics(app)
export const db = getFirestore(app);
export const messaging = getMessaging(app);

// Recieve FCM messages

onMessage(messaging, (payload) => {
    try {
      console.log('Received foreground message: ', payload);
      const notificationTitle = payload.data?.title as string;
      const notificationOptions = { body: payload.data?.body as string };
      const notification = new Notification(notificationTitle, notificationOptions);
      // self.registration.showNotification(notificationTitle, notificationOptions);
    } catch (err) {
      console.log(err);
    }
  });

// Shared firebase constants
export const BOOKINGS = "bookings"
export const WAITLIST = "waitlist"
export const USER = "user"

// Shared firebase queries 
export const fetchBookingsFiltered = async (): Promise<Booking[]> => {
  const filteredBookingsQuery = query(collection(db, BOOKINGS), where('date', '>=', ToLocaleDateStringISO(new Date())));
  return await getDocs(filteredBookingsQuery).then(querySnapshot => {
    if (!querySnapshot.empty) {
      const bookings = [] as Booking[]
      querySnapshot.docs.forEach(doc => {
        bookings.push(doc.data() as Booking)
      })
      return bookings
    } else {
      console.warn("Bookings are empty")
      return []
    }
  });
}


/// SW STUFF

export const getOrRegisterServiceWorker = () => {
  if (
    "serviceWorker" in navigator &&
    typeof window.navigator.serviceWorker !== "undefined"
  ) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

export const getFirebaseToken = async () => {
  try {
    if (messaging) {
      return getOrRegisterServiceWorker().then((serviceWorkerRegistration) => {
        return Promise.resolve(
          getToken(messaging, {
            vapidKey: config.REACT_APP_VAPIDKEY,
            serviceWorkerRegistration,
          })
        );
      });
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
};

export const saveFirebaseToken = async (userPrincipalName: string | undefined) => {
  if (!userPrincipalName)
    return;
  getFirebaseToken().then(async (currentToken: string | undefined) => {
    if (currentToken) {
      const docRef = doc(db, USER, userPrincipalName.toLowerCase() as string)
      const user = (await getDoc(docRef)).data() as User
      // We use a set to avoid duplicates
      const notificationTokens = new Set(user.notificationTokens ?? [])
      notificationTokens.add(currentToken)
      console.log(`User notification tokens: ${[...notificationTokens]}`);
      const updateObject = { notificationTokens: [...notificationTokens] }
      await setDoc(docRef, updateObject, {merge: true})
        .then(async () => {
          console.log("Successfully set the token");
        })
        .catch((error) => {
          console.log(`Unsuccessful token setting - ERR: ${error}`)
        })
    } else {
        console.log('No registration token available. Request permission to generate one.');
    }
  });
}
