import { Button } from "react-bootstrap";
import { CarparkCard } from "../CarparkCard/CarparkCard";

export const OfferableDays = ({props}: {props: any}) => {

    return(
        <CarparkCard 
        header={props.date.toLocaleDateString(undefined, { weekday: "short", day: "numeric", month: "short"})} 
        content={`Not offered`}
        button={<Button variant="success w-100" onClick={() => props.createRentalEntry(props)}>Offer</Button>}/>
    );
}
