import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { Nav } from "react-bootstrap";

function handleLogout(instance: IPublicClientApplication) {
    instance.logoutRedirect().catch(e => {
        console.error(e);
    });
}

/**
 * Renders a Nav.Link which, when selected, will redirect the page to the logout prompt
 */
export const SignOutMenuOption = () => {
    const { instance } = useMsal();

    return (
        <Nav.Link onClick={() => handleLogout(instance)}><i className="bi bi-box-arrow-left me-2"></i>Sign Out</Nav.Link>
    );
}
